<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="travel-input-outer mt-20 relative">
          <label class="filled"> ID do contrato </label>
          <a-input
            class="travel-input"
            type="number"
            placeholder="Insira o ID do contrato desta oportunidade"
            v-model="winOpportunity.contractId"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </div>
      </a-col>

      <a-col class="a-center mt-20" :span="24">
        <a-button
          type="primary"
          :loading="winOpportunity.loading"
          @click="submitWinOpt()"
        >
          Confirmar
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "WinOptModal",
  props: {
    tempOpportunity: Object,
  },
  data() {
    return {
      winOpportunity: {
        loading: false,
        contractId: undefined,
      },
      sales: [],
    };
  },
  methods: {
    async getContractSales() {
      try {
        const response = await this.$http.get(
          `/sale/list?page=1&per_page=10&contract-id=${this.winOpportunity.contractId}&order=desc&order-by=sale_date`
        );
        this.sales = response.data;
        this.updateSaleOptId(this.sales.data[0].id);
      } catch (error) {
        // Handle error if needed
        this.$message.warning("Não existe uma venda para este contrato.");
      }
    },
    updateContractOptId() {
      this.$http.post("/contract-v2/update-field", {
        id: this.winOpportunity.contractId,
        field: "opportunity_id",
        value: this.tempOpportunity.id,
      });
    },
    updateSaleOptId(saleId) {
      this.$http.post("/sale/update-field", {
        id: saleId,
        field: "opportunity_id",
        module: "opportunity",
        value: this.tempOpportunity.id,
        action: "update-opportunity-id",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o ID da oportunidade na venda ID ${saleId}.`,
        modified_by: {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        },
      });
    },
    submitWinOpt() {
      if (this.winOpportunity.contractId) {
        this.winOpportunity.loading = true;

        this.$http
          .post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "status",
            value: "Ganho",
          })
          .then(() => {
            this.tempOpportunity.status = "Ganho";
            this.tempOpportunity.contract_id = this.winOpportunity.contractId;

            this.$http.post("/opportunity/update-field", {
              id: this.tempOpportunity.id,
              field: "contract_id",
              value: this.winOpportunity.contractId,
            });

            this.updateContractOptId();
            this.getContractSales();

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempOpportunity.id,
              module: "opportunity",
              action: "win-opportunity",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} marcou a oportunidade <b>ID ${this.tempOpportunity.id}</b> como <b>Ganho</b>.`,
            });

            this.$message.success("Oportunidade ganha!");
            this.$emit("closeWinOptModal");
            this.winOpportunity.loading = false;
          })
          .catch(() => {
            this.winOpportunity.loading = false;
          });
      } else {
        this.$message.warning("Insira o ID do contrato.");
      }
    },
  },
};
</script>
